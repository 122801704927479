import type { StateProps } from "./types";
import { useState, useEffect } from "react";
import { useLocation, Navigate, Route } from "react-router-dom";
import "./App.css";
import MainLoader from "./Components/Tools/Feedback/MainLoader";
import RouterOAuth from "./Components/OAuth/RouterOAuth";
import CustomRouter from "./Components/Tools/CustomRouter/CustomRouter";
import Dashboard from "./Components/Dashboard/Dashboard";
import HandleError from "./Components/Tools/HandleError/HandleError";
import HandleSuccess from "./Components/Tools/HandleSuccess/HandleSuccess";
import MigrationRouter from "./Components/ImportAccessData/MigrationRouter";
import refreshToken from "./Components/Tools/Utils/APIRequests/refreshToken";
import Header from "./Components/OAuth/Header";
import Footer from "./Components/OAuth/Footer";
import { AppHeightProvider } from "./Components/Tools/context/appHeightContext";
import * as request from "./Components/Tools/Utils/APIRequests/request";

function App() {
    const [initialLoad, setInitialLoad]: StateProps<boolean> = useState<boolean>(true);
    const [isConnected, setIsConnected]: StateProps<boolean> = useState<boolean>(false);

    const location = useLocation();
    const isDisconnectPath = location.pathname === "/dashboard/disconnect";

    const handleChangeConnected = (newValue: boolean): void => {
        setIsConnected(newValue);
    }

    // console.log("App");
    // console.log(isConnected)

    const clearConnexionInfos = () => {
        request.get(`${process.env.REACT_APP_LEA_API}/disconnect`, undefined, undefined, false).then((req: request.Request): void => {
            // if (!req.isSuccessful && req.error.response)
            //     handleError(req.error, undefined, req.error.response.data.message);
        }).finally((): void => {
            localStorage.removeItem("JWT");
            localStorage.removeItem("REFRESH");
            localStorage.removeItem("EXPIRATION");
        });

        return true;
    }

    useEffect((): void => {
        // get params from url
        const urlParams = new URLSearchParams(window.location.search);
        const refreshT = urlParams.get('refresh_token');

        if (refreshT != null || refreshT != undefined || refreshT != "") {
            if (refreshT != null) {
                localStorage.setItem('REFRESH', refreshT);
            }
        }

        async function tokenIsValid(): Promise<void> {
            // Transformation de la date d'expiration du token en objet date
            const expirationDate = new Date(parseInt(localStorage.getItem('EXPIRATION') as string) * 1000 ?? "0");
            // console.log(expirationDate);

            // Si la date d'expiration est égale à la date du jour on refresh le token
            if (expirationDate <= new Date()) {
                const refresh = await refreshToken(refreshT ?? localStorage.getItem('REFRESH'));
                if (refresh.result)
                    setIsConnected(true);
            } else {
                setIsConnected(true);
            }

            if (refreshT != null || refreshT != undefined || refreshT != "") {
                if (refreshT != null) {
                    const refresh = await refreshToken(refreshT ?? localStorage.getItem('REFRESH'));
                    if (refresh.result)
                        setIsConnected(true);
                }
            }



            setInitialLoad(false);
        }

        tokenIsValid();
    }, []);

    if (process.env.REACT_APP_MAINTENANCE_MODE === "1") {
        return (
            <div className="flex flex-col justify-between h-screen bg-MAIN_COLOR font-worksans">
                <Header/>
                <main className={"bg-MAIN_COLOR w-screen flex justify-center items-center"}>
                    <div className="flex flex-col bg-white shadow w-[760px] h-[320px] items-center space-y-6 rounded-lg m-auto">
                        <div className={"flex w-full h-[62px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                            <div className={"flex flex-col ml-[43px] items-start justify-center"}>
                                <div className={"text-white font-bold text-xl"}>MAINTENANCE EN COURS</div>
                            </div>
                        </div>
                        <div className={"flex"}>
                            <div className={"text-lg w-full text-center space-y-5 pt-10"}>
                                <p>Nous effectuons actuellement des travaux de maintenance sur l'application afin d'améliorer votre expérience.</p>
                                <p>Nous nous excusons pour tout inconvénient que cela pourrait causer.</p>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer isConnected={false} handleChangeConnected={handleChangeConnected}/>
            </div>
        );
    }

    return (
        <AppHeightProvider>
            <div className="App font-worksans">
                <HandleError>
                    <HandleSuccess>
                        {initialLoad ? (
                            <MainLoader/>
                        ) : (
                            <CustomRouter>
                                <Route path={""} element={
                                    isConnected ? <Navigate to={"/dashboard/home"}/> : <Navigate to={"/oauth"}/>
                                }/>
                                <Route path={"/oauth/*"} element={
                                    <RouterOAuth isConnected={isConnected} handleChangeConnected={handleChangeConnected}/>
                                }/>
                                {/*<Route path={"/dashboard/disconnect"} element={*/}
                                {/*    (isConnected && isDisconnectPath) &&*/}
                                {/*    clearConnexionInfos() &&*/}
                                {/*    <Navigate to={"/oauth"}/>*/}
                                {/*}/>*/}
                                <Route path={"/dashboard/*"} element={
                                    isConnected && !isDisconnectPath ?
                                        <Dashboard isConnected={isConnected} handleChangeConnected={handleChangeConnected}/> :
                                        clearConnexionInfos() &&
                                        <Navigate to={"/oauth"}/>
                                }/>
                                {/*<Route path={"/dashboard/disconnect"} element={*/}
                                {/*    (isConnected && isDisconnectPath) ?*/}
                                {/*        <Navigate to={"/dashboard/home"}/> :*/}
                                {/*        clearConnexionInfos() &&*/}
                                {/*        <Navigate to={"/oauth"}/>*/}
                                {/*}/>*/}
                                <Route path={"/import-access-data/*"} element={
                                    <MigrationRouter isConnected={isConnected} handleChangeConnected={handleChangeConnected}/>
                                }/>
                            </CustomRouter>
                        )}
                    </HandleSuccess>
                </HandleError>
            </div>
        </AppHeightProvider>
    );
}

export default App;
