import { Navigate, Route } from "react-router-dom";
import CustomRouter from "../Tools/CustomRouter/CustomRouter";
import Login from "./Login/Login";
import SSOLogin from "./Login/SSOLogin";
import MissingPassword from "./MissingPassword/MissingPassword";
import FirstLogin from "./FirstLogin/FirstLogin";
import UnknownIdentifier from "./UnknownIdentifier/UnknownIdentifier";
import UserGuide from "../UserGuide/UserGuide";
import Support from "../Support/Support";
import LegalNotice from "../LegalNotice/LegalNotice";
import Header from "./Header";
import Footer from "./Footer";
import NewPassword from "./MissingPassword/NewPassword";

interface RouterOAuthProps {
    isConnected: boolean,

    handleChangeConnected(newValue: boolean): any;
}

export default function RouterOAuth({isConnected, handleChangeConnected}: RouterOAuthProps) {
    return <div className="flex flex-col h-screen bg-MAIN_COLOR justify-between">
        <Header/>
        <main className={"bg-MAIN_COLOR w-screen flex justify-center items-center"}>
            <CustomRouter>
                <Route path={""} element={
                    <Navigate to={'login'}/>
                }/>
                <Route path={"login/classic"} element={
                    <Login handleChangeConnected={handleChangeConnected}/>
                }/>
                <Route path={"login"} element={
                    <SSOLogin handleChangeConnected={handleChangeConnected}/>
                }/>
                <Route path={"missing-password"} element={
                    <MissingPassword/>
                }/>
                <Route path={"new-password"} element={
                    <NewPassword/>
                }/>
                <Route path={"first-login"} element={
                    <FirstLogin/>
                }/>
                <Route path={"unknown-identifier"} element={
                    <UnknownIdentifier/>
                }/>
                <Route path={"user-guide"} element={
                    <UserGuide/>
                }/>
                <Route path={"support"} element={
                    <Support/>
                }/>
                <Route path={"legal-notice"} element={
                    <LegalNotice/>
                }/>
            </CustomRouter>
        </main>
        <Footer isConnected={isConnected} handleChangeConnected={handleChangeConnected}/>
    </div>
}
